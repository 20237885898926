import "../src/assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap";
import PublicRoutes from "./routes/PublicRoutes";

import { useEffect } from "react";

function App() {

  return (
    <>
      <PublicRoutes />
    </>
  );
}

export default App;
